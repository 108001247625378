/** @format */

import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { FixedSizeList as List } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"
import InfiniteLoader from "react-window-infinite-loader"
import { Centrifuge } from "centrifuge"
import i18n from "util/i18n"
import _ from "lodash"
import SinglePing from "./SinglePing"
import { getPingFeed, getTotalPingCount } from "services/pingService"
import Loading from "./Loading"
import { PingList, TotalPings, SidePanelWrapper } from "./styledComponents"
import { cfgKeys } from "../../App"
import Cookies from "js-cookie"

const propTypes = {
  onShowMyPings: PropTypes.func,
  chkBoxStatus: PropTypes.bool
}

const SidePanel = props => {
  const sid = Cookies.get("SID")
  const tpcVal = sid + "_tpc"
  const isNewPing = sid + "_new"
  const centrig = useRef()
  const channelRef = useRef()
  const [pings, setPings] = useState([])
  const [hasNextPage, setHasNextPage] = useState(true)
  const [isChecked, setIsChecked] = useState(props.chkBoxStatus)
  const myChkBoolVal = useRef(false)
  const [totalPingCount, setTotalPingCount] = useState(true)
  const myRef = useRef()
  const myImgRef = useRef()
  const myTxtRef = useRef()
  const myChkBoxRef = useRef()
  const itemCount = hasNextPage ? pings.length + 1 : pings.length

   useEffect(() => {
    centrig.current = new Centrifuge(cfgKeys["centrifugows"], {
      token: cfgKeys["centrifugeToken"],
      timeout: 10000
    })
    centrig.current.connect()
  }, [])
  const bartanimate = () => {
    if (myRef.current.style.width === "14rem" || myRef.current.style.width === "") {
      myRef.current.style.width = "6.2rem"
      myImgRef.current.src = "/static/media/expand.png"
      myTxtRef.current.style.fontSize = "11px"
      myChkBoxRef.current.style.height = "11px"
    } else {
      myRef.current.style.width = "14rem"
      myImgRef.current.src = "/static/media/collapse.png"
      myTxtRef.current.style.fontSize = "16px"
      myChkBoxRef.current.style.height = "16px"
    }
  }
  const loadPingFeedNCounter = (sttIndex, stpIndex) => {
    getTotalPingCount().then(data => {
      setTotalPingCount(data.totalPingCount)
    })
      getPingFeed(sttIndex, stpIndex).then(data => {
      const { rtlivefeed = { pings: [] } } = data || {}
      setHasNextPage(rtlivefeed.hasNext)
      setPings(p => _.orderBy(_.uniqBy(p.concat(rtlivefeed.pings), "pingts"), ["pingts"], ["desc"]))
    })
  }

    const handleChange = () => {
        setPings([])
    myChkBoolVal.current = !isChecked
    setIsChecked(!isChecked)
    props.onShowMyPings(!isChecked)
    loadPingFeedNCounter(0, 20)
  }

  useEffect(() => {
    channelRef.current = centrig.current.newSubscription("pingnew", { token: cfgKeys["cct1"] })
    channelRef.current.subscribe()
    channelRef.current.on("publication", function(ctx) {
      if (myChkBoolVal.current) {
        if (ctx.data.hasOwnProperty(isNewPing)) {
          setPings(p => [ctx.data.ping].concat(p))
        } else {
          setPings(p => _.orderBy(_.uniqBy(p.concat(pings), "pingts"), ["pingts"], ["desc"]))
        }
      } else {
        setPings(p => [ctx.data.ping].concat(p))
      }
    })

    return () => {
      channelRef.current.unsubscribe()
      channelRef.current.removeAllListeners()
    }
  }, [])

  useEffect(() => {
    channelRef.current = centrig.current.newSubscription("pingtotalcount", {
      token: cfgKeys["cct2"]
    })
    channelRef.current.subscribe()
    channelRef.current.on("publication", function(ctx) {
      if (myChkBoolVal.current) {
          if (ctx.data.hasOwnProperty(tpcVal)) {
              console.log("am here")
          setTotalPingCount(ctx.data[tpcVal])
        } else {
          setTotalPingCount(Cookies.get(tpcVal))
        }
      } else {
        setTotalPingCount(ctx.data.tpc)
      }
    })
    return () => {
      channelRef.current.unsubscribe()
      channelRef.current.removeAllListeners()
    }
  }, [])

  const loadNextPage = (startIndex, stopIndex) => {
    loadPingFeedNCounter(startIndex, stopIndex)
  }

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => !hasNextPage || index < pings.length

  // Render an item or a loading indicator.
  const Item = ({ index, style }) => {
    let content
    if (!isItemLoaded(index)) {
      content = (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          <Loading />
        </div>
      )
    } else {
      content = <SinglePing ping={pings[index]} />
    }
    return <div style={style}>{content}</div>
  }

  return (
    <SidePanelWrapper ref={myRef}>
      <div>
        <span>
          <img
            alt="collapse"
            ref={myImgRef}
            src="/static/media/collapse.png"
            onClick={bartanimate}
          />
        </span>
      </div>
      <div>
        <span ref={myTxtRef}>
          <input
            id="shwMyPngsChkBx"
            type="checkbox"
            ref={myChkBoxRef}
            onChange={handleChange}
            value={isChecked}
            defaultChecked={isChecked}
          />{" "}
          {i18n.t("Labels.loadMyPings")}
        </span>
      </div>
      <TotalPings>
        <span>{i18n.t("Labels.totalPings")}</span>
        <span id="tpc">
          {totalPingCount === undefined ? <Loading /> : totalPingCount.toLocaleString()}
        </span>
      </TotalPings>
      <PingList>
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadNextPage}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  className="List"
                  height={height}
                  itemCount={itemCount}
                  itemSize={100}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  width={width}
                >
                  {Item}
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </PingList>
    </SidePanelWrapper>
  )
}

SidePanel.propTypes = propTypes
SidePanel.displayName = "SidePanel"
export default SidePanel
