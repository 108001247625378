/** @format */

import React from "react"
import styled from "styled-components"

const LoadingContainer = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
`

const SpinnerContainer = styled.div`
  display: inline-block;
  position: relative;
  height: 1rem;
  width: 5rem;
  div {
    position: absolute;
    top: 0.1rem;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 0.5rem;
    animation: lds-ellipsis1 0.6s infinite;
  }
  div:nth-child(2) {
    left: 0.5rem;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 2rem;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 3.5rem;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(1.5rem, 0);
    }
  }
`

function Loading(props) {
  return (
    <LoadingContainer {...props} data-test-component="Loading">
      <SpinnerContainer>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </SpinnerContainer>
    </LoadingContainer>
  )
}

export default Loading
