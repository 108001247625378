/** @format */

import React, { useCallback, useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import mapboxgl from "mapbox-gl"
import GlobalStyle from "util/GlobalStyle"
import { AnimateSharedLayout, motion } from "framer-motion"
import i18n from "util/i18n"

import { debounce, orderBy } from "lodash"
import { NavBar, PingLeaf, SidePanel } from "components"
import { getBaseUrl, getPings, isLoggedIn, toggleMyInfo, createPing } from "services/pingService"
import { activePingsList, cfgKeys } from "../../App"
import { Centrifuge } from "centrifuge"
import Cookies from "js-cookie"
import ReactGA from "react-ga4"
import {
  Main,
  LeafWrapper,
  RecentPingDisplay,
  PingCreatedSection,
  AppWrapper
} from "./styledComponents"

const randString = () => {
  Math.random()
    .toString(36)
    .substr(2, 9)
}

const SPIDER_LEAVES_LAYER_NAME_PREFIX = "spider-leaves"
// marker: use Mapbox's Marker. layer: Use a Mabpbox point layer
const SPIDER_TYPE = "layer"
// Max leave to display when spiderify to prevent filling the map with leaves
const MAX_LEAVES_TO_SPIDERIFY = 255
// When below number, will display leave as a circle. Over, as a spiral
const CIRCLE_TO_SPIRAL_SWITCHOVER = SPIDER_TYPE === "marker" ? 1 : 2
// this is distance between pings when you click on the disc/circle
const CIRCLE_OPTIONS = {
  distanceBetweenPoints: 100
}
const SPIRAL_OPTIONS = {
  rotationsModifier: 1250, // Higher modifier = closer spiral lines
  distanceBetweenPoints: SPIDER_TYPE === "marker" ? 42 : 32, // Distance between points in spiral
  radiusModifier: 50000, // Spiral radius
  lengthModifier: 1000 // Spiral length modifier
}
const SPIDER_LEGS = false
const SPIDER_LEGS_LAYER_NAME = `spider-legs-${randString()}`
const SPIDER_LEGS_PAINT_OPTION = {
  "line-width": 1,
  "line-color": "rgba(128, 128, 128, 0.5)"
}
const SPIDER_LEAVES_LAYER_NAME = `spider-leaves-${randString()}`

const propTypes = {
  activePings: PropTypes.arrayOf(
    PropTypes.shape({
      pingid: PropTypes.string,
      type: PropTypes.string,
      category: PropTypes.string
    })
  ),
  loggedIn: PropTypes.bool,
  setLoggedIn: PropTypes.func,
  showPing: PropTypes.object
}
const defaultProps = {
  setLoggedIn: () => {}
}

function Earth(props) {
  const centrig = useRef()
  const channelRef = useRef()
  const navRef = useRef()
  const popupRef = useRef()
  const mapBoxRef = useRef()
  const userEmaill = useRef()
  const mapContainer = useRef()
  const myPings = useRef(false)
  const isLoading = useRef(true)
  const userLoggedIn = useRef(false)
  const isFlying = useRef(false)
  const showPingOnLoad = useRef(props.showPing)
  const [pingAggregates, setPingAggregates] = useState({})
  const [currentPing, setCurrentPing] = useState(props.showPing)
  const [imagesLeftToLoad, setImagesLeftToLoad] = useState(activePingsList.length)
  const [isNewPing, setIsNewPing] = useState(false)
  const [mapBoxState, setMapBoxState] = useState({
    lat: Cookies.get("lt"),
    lng: Cookies.get("lg"),
    zoom: 10
  })

  // cleaning routines
  const _removeSourceAndLayer = (map, id) => {
    map.getLayer(id) && map.removeLayer(id)
    map.getSource(id) && map.removeSource(id)
  }

  const clearSpiderifiedCluster = map => {
    if (!map) return
    _removeSourceAndLayer(map, SPIDER_LEGS_LAYER_NAME)
    _removeSourceAndLayer(map, SPIDER_LEAVES_LAYER_NAME)
  }

  const clearMap = () => {
    if (!mapBoxRef.current) return
    clearSpiderifiedCluster(mapBoxRef.current)
    _removeSourceAndLayer(mapBoxRef.current, "clusters")
    _removeSourceAndLayer(mapBoxRef.current, "cluster-count")
    _removeSourceAndLayer(mapBoxRef.current, "unclustered-point")
    _removeSourceAndLayer(mapBoxRef.current, "pings")
  }
  const flyToLoc = (lg, lt) => {
    //mapBoxRef.current.fire("flystart")
    mapBoxRef.current.flyTo({
      center: [lg, lt],
      zoom: 17
    })
  }

  // when user creates a new ping in the current session the following code handles it
  const handlePingTypeSelect = pingType => {
    createPing(pingType).then(data => {
      // reload all the pings
      setPingAggregates({})

      const lnglatObj = { lng: data.lng, lat: data.lat }
      const rt = mapBoxRef.current.project(lnglatObj)
      const clickPingCoordObj = {
        lngLat: lnglatObj,
        point: rt,
        originalEvent: {}
      }

      // let big brother know that we created a ping
      ReactGA.event({
        category: "Ping Creation",
        action: "Ping Created",
        value: 99
      })

      /*mapBoxRef.current.on("flystart", function() {
        isFlying.current = true
      })
      mapBoxRef.current.on("flyend", function() {
        isFlying.current = false
                
        setTimeout(() => {}, 1500)
        setTimeout(() => {
          mapBoxRef.current.fire("click", clickPingCoordObj)
        }, 2000)
        setTimeout(() => {
          mapBoxRef.current.fire("click", clickPingCoordObj)
        }, 2000)
      }) 
      */
       flyToLoc(data.lng, data.lat)
//      })
      setTimeout(() => {}, 2000)
      setCurrentPing(data)
      setIsNewPing(true)
    })
  }

  // for the side panel checkbox
  const handleShowMyPings = checked => {
    clearMap()
    toggleMyInfo()
    myPings.current = checked
    setPingAggregates({})
    onMapMove()
  }
    useEffect(()=> {
	//Implementing the setInterval method
       setInterval(() => {
	    onMapMove()
        }, 178000);
    })
  useEffect(() => {
    mapboxgl.accessToken = cfgKeys["mapbox"]
    ReactGA.initialize(cfgKeys["ga"])
    ReactGA.send({
      page: "Home",
      title: "Ping Home Page",
      hitType: "pageview"
    })

    // NOTE: check these three few lines
    const objtemp = { lat: mapBoxState.lat, lng: mapBoxState.lng }
    const { lat, lng } = objtemp
    if (!lat || !lng) return
    setMapBoxState({
      lat,
      lng
    })

    mapBoxRef.current = new mapboxgl.Map({
      zoom: 10,
      center: [mapBoxState.lng, mapBoxState.lat],
      style: "mapbox://styles/mapbox/dark-v10",
      maxZoom: 18,
      minZoom: 2.35,
      container: mapContainer.current,
      dragRotate: false,
      zoomControl: false,
      fadeDuration: 0,
      pitchWithRotate: false,
      renderWorldCopies: true,
      attributionControl: false,
      maxBoundsViscosity: 1.0
    })
    mapBoxRef.current.dragRotate.disable()
    // disable map rotation using touch rotation gesture
    mapBoxRef.current.touchZoomRotate.disableRotation()
    mapBoxRef.current.on("load", onLoad)
    mapBoxRef.current.on("moveend", function() {
      if (isFlying.current) {
        mapBoxRef.current.fire("flyend")
      }
      onMapMove()
    })
    return () => {
      mapBoxRef.current.off("load", onLoad)
      mapBoxRef.current.off("moveend", onMapMove)
    }
  }, [])

  const _generateEquidistantPointsInCircle = ({ totalPoints = 1 }) => {
    let points = []
    let theta = (Math.PI * 2) / totalPoints
    let angle = theta
    for (let i = 0; i < totalPoints; i++) {
      angle = theta * i
      points.push({
        x: CIRCLE_OPTIONS.distanceBetweenPoints * Math.cos(angle),
        y: CIRCLE_OPTIONS.distanceBetweenPoints * Math.sin(angle)
      })
    }
    return points
  }

  const _generateEquidistantPointsInSpiral = ({ totalPoints = 10 }) => {
    let points = [{ x: 0, y: 0 }]
    // Higher modifier = closer spiral lines
    const rotations = totalPoints * SPIRAL_OPTIONS.rotationsModifier
    const distanceBetweenPoints = SPIRAL_OPTIONS.distanceBetweenPoints
    const radius = totalPoints * SPIRAL_OPTIONS.radiusModifier
    // Value of theta corresponding to end of last coil
    const thetaMax = rotations * 2 * Math.PI
    // How far to step away from center for each side.
    const awayStep = radius / thetaMax
    for (
      let theta = distanceBetweenPoints / awayStep;
      points.length <= totalPoints + SPIRAL_OPTIONS.lengthModifier;

    ) {
      points.push({
        x: Math.cos(theta) * (awayStep * theta),
        y: Math.sin(theta) * (awayStep * theta)
      })
      theta += distanceBetweenPoints / (awayStep * theta)
    }
    return points.slice(0, totalPoints)
  }

  const spiderifyCluster = ({ map, source, clusterToSpiderify }, popupRegisterCallback) => {
    let spiderlegsCollection = []
    let spiderLeavesCollection = []
    let leavesCoordinates = {}
    const onGetClusterLeaves = (error, features) => {
      if (error) {
        console.warn("Cluster does not exists at this zoom")
        return
      }

      // Position cluster's leaves in circle if below threshold, spiral otherwise
      if (features.length < CIRCLE_TO_SPIRAL_SWITCHOVER) {
        leavesCoordinates = _generateEquidistantPointsInCircle({ totalPoints: features.length })
      } else {
        leavesCoordinates = _generateEquidistantPointsInSpiral({ totalPoints: features.length })
      }

      let clusterXY = map.project(clusterToSpiderify.coordinates)

      // Generate spiderlegs and leaves coordinates
      orderBy(features, [f => f.properties.pingts], ["desc"]).forEach((element, index) => {
        let spiderLeafLatLng = map.unproject([
          clusterXY.x + leavesCoordinates[index].x,
          clusterXY.y + leavesCoordinates[index].y
        ])
        if (SPIDER_TYPE) {
          spiderLeavesCollection.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [spiderLeafLatLng.lng, spiderLeafLatLng.lat]
            },
            properties: element.properties
          })
        }

        if (SPIDER_LEGS) {
          spiderlegsCollection.push({
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: [
                clusterToSpiderify.coordinates,
                [spiderLeafLatLng.lng, spiderLeafLatLng.lat]
              ]
            }
          })
        }
      })

      // Draw spiderlegs and leaves coordinates
      if (SPIDER_LEGS && !map.getLayer(SPIDER_LEGS_LAYER_NAME)) {
        map.addLayer(
          {
            id: SPIDER_LEGS_LAYER_NAME,
            type: "line",
            source: {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: spiderlegsCollection
              }
            },
            paint: SPIDER_LEGS_PAINT_OPTION
          },
          "clusters"
        )
      }
      if (SPIDER_TYPE.toLowerCase() === "layer" && !map.getLayer(SPIDER_LEAVES_LAYER_NAME)) {
        map.addLayer({
          id: SPIDER_LEAVES_LAYER_NAME,
          type: "symbol",
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: spiderLeavesCollection
            }
          },
          layout: {
            "icon-image": ["get", ["string", "iconUrl"]],
            "icon-size": 0.25,
            "icon-allow-overlap": true
          }
        })
        map.on("click", SPIDER_LEAVES_LAYER_NAME, function(e) {
          var coordinates = e.features[0].geometry.coordinates.slice()

          // Ensure that if the map is zoomed out such that
          // multiple copies of the feature are visible, the
          // popup appears over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
          }

          const placeholder = document.createElement("div")
          e.features[0].properties["coords"] = [e.lngLat.lng, e.lngLat.lat]
          ReactDOM.render(<PingLeaf pingInfo={e.features[0].properties} />, placeholder)
          const popup = new mapboxgl.Popup({
            className: "ping-leaf-popup",
            closeButton: false,
            closeOnMove: true
          })
            .setLngLat(coordinates)
            .setDOMContent(placeholder)
            .addTo(map)
          popupRegisterCallback(popup)
        })
      }
    }
    map
      .getSource(source)
      .getClusterLeaves(clusterToSpiderify.id, MAX_LEAVES_TO_SPIDERIFY, 0, onGetClusterLeaves)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchPings = useCallback(
    debounce((sEBnds, nWBnds) => {
      // these directions are not used for now and we fetch everything on the map in pingService
      getPings(sEBnds.lat, sEBnds.lng, nWBnds.lat, nWBnds.lng, isNewPing).then(data => {
        setPingAggregates(data)
        setIsNewPing(false)
      })
    }, 500),
    []
  )

  // map event
  const onMapMove = () => {
    if (!mapBoxRef.current) return
    if (currentPing) {
    } else {
      const bounds = mapBoxRef.current.getBounds()
      const sEBnds = bounds.getSouthEast().wrap()
      const nWBnds = bounds.getNorthWest().wrap()
      fetchPings(sEBnds, nWBnds)
    }
  }
  // map event
  const onLoad = () => {
    isLoggedIn()
      .then(data => {
        if (data !== undefined) {
          userLoggedIn.current = data.status
          userEmaill.current = data.useremail
        } else {
          userLoggedIn.current = false
          userEmaill.current = ""
        }
      })
      .catch(error => {
        console.log("got error at loggedin")
      })

    isLoading.current = false
    // load/add the pingstypes into the map, this is async in nature
    activePingsList.forEach(p => {
      const imgUrl = `${getBaseUrl(false)}${p.img}`
      mapBoxRef.current.loadImage(imgUrl, (error, image) => {
        if (error) throw error
        setImagesLeftToLoad(draft => draft - 1)
        mapBoxRef.current.addImage(p.type.toLowerCase(), image)
      })
    })
    // The following are for different on click handlers
    // 1) click on cluster, which has pings grouped
    mapBoxRef.current
      .on("click", "clusters", e => {
        const featuresAtPoint = mapBoxRef.current.queryRenderedFeatures(e.point)
        const hasLeaf =
          featuresAtPoint.filter(feature =>
            feature.layer.id.startsWith(SPIDER_LEAVES_LAYER_NAME_PREFIX)
          ).length > 0
        if (hasLeaf) return
        const features = featuresAtPoint.filter(feature => feature.layer.id === "clusters")
        let clusterId = features[0].properties.cluster_id

        // Zoom on cluster or spiderify it
        if (mapBoxRef.current.getZoom() < 12) {
          mapBoxRef.current.getSource("pings").getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return
            mapBoxRef.current.jumpTo({
              center: features[0].geometry.coordinates,
              zoom: 12
            })
          })
        } else {
          console.log("opening spider")
          spiderifyCluster(
            {
              map: mapBoxRef.current,
              source: "pings",
              clusterToSpiderify: {
                id: clusterId,
                coordinates: features[0].geometry.coordinates
              }
            },
            popup => (popupRef.current = popup)
          )
        }
      })
      .on("mouseenter", "clusters", function() {
        mapBoxRef.current.getCanvas().style.cursor = "pointer"
      })
      .on("mouseleave", "clusters", function() {
        mapBoxRef.current.getCanvas().style.cursor = ""
      })
      .on("zoomstart", () => {
        clearSpiderifiedCluster(mapBoxRef.current)
      })
      .on("click", e => {
        const features = mapBoxRef.current.queryRenderedFeatures(e.point)
        let isOverLeaf = false
        features.forEach(feature => {
          const { layer } = feature
          isOverLeaf = isOverLeaf || layer.id.startsWith(SPIDER_LEAVES_LAYER_NAME_PREFIX)
        })
        navRef.current.closeSelector()
        if (!isOverLeaf && popupRef.current && !popupRef.current.isOpen())
          clearSpiderifiedCluster(mapBoxRef.current)
      })
      .on("click", "unclustered-point", function(e) {
        console.log("clicking on ping..")
        // When a click event occurs on a feature in
        // the unclustered-point layer, open a popup at
        // the location of the feature, with
        // description HTML from its properties.
        var coordinates = e.features[0].geometry.coordinates.slice()

        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        const placeholder = document.createElement("div")
        e.features[0].properties["coords"] = [e.lngLat.lng, e.lngLat.lat]
        ReactDOM.render(<PingLeaf pingInfo={e.features[0].properties} />, placeholder)
        popupRef.current = new mapboxgl.Popup({
          className: "ping-leaf-popup",
          closeButton: false,
          closeOnMove: true
        })
          .setLngLat(coordinates)
          .setDOMContent(placeholder)
          .addTo(mapBoxRef.current)
      })
      onMapMove()
  }
  // when new data is available in pingAggregates, call this
  useEffect(() => { if (
      typeof pingAggregates == "undefined" ||
          !mapBoxRef.current ||
          isLoading.current ||
          Object.keys(pingAggregates).length === 0 ||
          imagesLeftToLoad
  )
      return
    clearMap()
    const renderMap = pingAggregates => {
      if (mapBoxRef.current.getSource("pings")) {
        // if the source is already present, just update it with latest data
        mapBoxRef.current.getSource("pings").setData(pingAggregates)
      } else {
        // create the layers and source only once
        mapBoxRef.current.addSource("pings", {
          type: "geojson",
          data: pingAggregates,
          cluster: true,
          clusterRadius: 100
        })
        mapBoxRef.current.addLayer({
          id: "clusters",
          type: "circle",
          source: "pings",
          filter: ["has", "point_count"],
          paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#FFFFFF",
              10,
              "#FFFFFF",
              100,
              "#FFFFFF",
              1000,
              "#FFFFFF"
            ],
            "circle-radius": ["step", ["get", "point_count"], 20, 10, 30, 100, 40, 1000, 50]
          }
        })

        mapBoxRef.current.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "pings",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 13
          }
        })

        mapBoxRef.current.addLayer({
          id: "unclustered-point",
          type: "symbol",
          source: "pings",
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-image": ["get", ["string", "iconUrl"]],
            "icon-size": 0.25
          }
        })
        if (showPingOnLoad.current) {
          setCurrentPing(showPingOnLoad.current)
          showPingOnLoad.current = undefined
        }
      }
    }
    renderMap(pingAggregates)
  }, [pingAggregates, imagesLeftToLoad])

  // when new pings arrive from other users, the following code notifies the current user
  useEffect(() => {
    centrig.current = new Centrifuge(cfgKeys["centrifugows"], {
      token: cfgKeys["centrifugeToken"],
      timeout: 10000
    })
    centrig.current.connect()
    channelRef.current = centrig.current.newSubscription("pingnew", { token: cfgKeys["cct1"] })
    channelRef.current.subscribe()
    channelRef.current.on("publication", function(ctx) {
      setPingAggregates(p => {
        return {
          ...p,
          features: [ctx.data.pingGeoJson].concat(p.features || [])
        }
      })
      setIsNewPing(true)
    })
    return () => {
      channelRef.current.unsubscribe()
      channelRef.current.removeAllListeners()
    }
  }, [])

  return (
    <AppWrapper>
      <GlobalStyle />
      <NavBar
        activePings={props.activePings}
        ref={navRef}
        loggedIn={userLoggedIn.current}
        onLogout={() => props.setLoggedIn(false)}
        onPingTypeSelect={handlePingTypeSelect}
        userEmail={userEmaill.current}
        mappo={mapBoxRef.current}
        hideLinks
      />
      <Main ref={mapContainer} />
      <SidePanel onShowMyPings={handleShowMyPings} chkBoxStatus={cfgKeys["smpstatus"]} />
      <AnimateSharedLayout>
        {currentPing && (
          <motion.div
            key="leaf"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <RecentPingDisplay onClick={() => setCurrentPing(undefined)}>
              <LeafWrapper>
                <div>
                  <PingCreatedSection>
                    {i18n.t("Labels.pingCreated")}
                    <div>{i18n.t("Labels.approximateLocation")}</div>
                  </PingCreatedSection>
                  <PingLeaf pingInfo={currentPing} />
                </div>
              </LeafWrapper>
            </RecentPingDisplay>
          </motion.div>
        )}
      </AnimateSharedLayout>
    </AppWrapper>
  )
}

Earth.propTypes = propTypes
Earth.defaultProps = defaultProps
Earth.displayName = "Earth"

export default Earth
