/** @format */

import i18next from "i18next"
import LngDetector from "i18next-browser-languagedetector"

import en from "locales/en"
import de from "locales/de"

i18next.use(LngDetector).init({
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false
  },

  resources: {
    en,
    de
  }
})

export default i18next
