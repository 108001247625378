/** @format */

import styled, { css } from "styled-components/macro"

const navBackgroundColor = "#212121"

export const navHeight = "4.625rem"

export const Links = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  max-width: 45rem;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0 1rem;
`

export const LinkImage = styled.img`
  height: 1.25rem;
  width: 1.25rem;
  object-fit: scale-down;
  margin-bottom: 0.625rem;
`

export const LogoText = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  display: flex;
`

const LinkStyles = css`
  color: ${props => (props.isActive ? "#fff" : "#ddd")};
  font-weight: ${props => (props.isActive ? "700" : "400")};
  font-size: 0.75rem;
  border: none;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.5rem;
  flex-direction: column;
  flex-shrink: 0;
  text-decoration: none;
  height: fit-content;
`
export const LoginLogoutBtnDiv = styled.div`
  font-weight: "400";
  font-size: 0.75rem;
  border: none;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.5rem;
  margin-right: 0px;
  flex-direction: row;
  flex-shrink: 0;
  text-decoration: none;
  height: fit-content;
  > a,
  ${Links} a {
    ${LinkStyles}
  }
`

export const StyledNavBar = styled.nav`
  grid-area: header;
  height: ${navHeight};
  background-color: ${navBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
  box-shadow: 0 5px 0.25rem rgba(0, 0, 0, 0.3);
  position: relative;

  > a,
  ${Links} a {
    ${LinkStyles}
  }

  ${props =>
    !props.hideLinks &&
    `
    @media (max-width: 50rem) {
      a > span { display: none }
      a > ${LinkImage} { margin: 0 }
      ${Links} {
        max-width: 100%;
      }
    }
  `}
`

export const CreatePingButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translate(-50%, -65%);
  z-index: 3;

  border-radius: 50%;
  overflow: hidden;
  padding: 0;

  &:active {
    box-shadow: inset 0 -2px 1px rgba(255, 255, 255, 0.2), inset 0 3px 2px rgba(0, 0, 0, 0.12);
  }

  img {
    max-height: 5.5rem;
    max-width: 5.5rem;
  }
`

export const NavButton = styled.a`
  ${LinkStyles}
`

export const BackgroundOverlay = styled.div`
  position: fixed;
  top: ${navHeight};
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
`

export const StyledSelector2 = styled.div`
  padding: 2rem;
  padding-top: 3rem;
  width: 41rem;
  max-width: 100vw;
  background-color: ${navBackgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  left: 50%;
  position: absolute;
  top: ${navHeight};
  transform: translateX(-50%);
  z-index: 2;
  font-weight: 200;
  border-radius: 0 0 2rem 2rem;

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }
`

export const StyledSelector = styled.div`
  background-color: ${navBackgroundColor};
  border-radius: 0 0 44rem 44rem;
  display: grid;
  height: 22rem;
  left: 50%;
  max-width: 100%;
  position: absolute;
  top: ${navHeight};
  transform: translateX(-50%);
  width: 44rem;
  z-index: 2;
  grid-template-columns: 1rem repeat(7, 1fr) 1rem;
  grid-template-rows: 1rem repeat(4, 1fr) 1rem;
  grid-template-areas:
    "spacer-left spacer-top spacer-top spacer-top spacer-top spacer-top spacer-top spacer-top spacer-right"
    "spacer-left sad . welcome-text welcome-text welcome-text . happy spacer-right"
    "spacer-left sick sick welcome-text welcome-text welcome-text healthy healthy spacer-right"
    "spacer-left . change change . thanks thanks . spacer-right"
    "spacer-left . change change love thanks thanks . spacer-right"
    "spacer-left spacer-bottom spacer-bottom spacer-bottom spacer-bottom spacer-bottom spacer-bottom spacer-bottom spacer-right";
  box-shadow: 0 5px 0.25rem rgba(0, 0, 0, 0.3);
  font-weight: 200;
`

export const PingButton = styled.div`
  max-width: 3.75rem;
  grid-area: ${props => props.pingType};
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    align-items: center;
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    font-size: 0.75rem;
    justify-content: center;
    line-height: 1.625rem;
    height: fit-content;
    padding: 0;
    img {
      height: 3.75rem;
      width: 3.75rem;
    }
    &:active {
      text-shadow: 0 0 0.5rem rgba(255, 255, 255, 1);
    }
  }
`

export const PingWelcomeText = styled.span`
  font-size: 1.25rem;
  grid-area: welcome-text;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 2rem;
`

export const Spacer = styled.div`
  grid-area: spacer- ${props => props.position};
`
