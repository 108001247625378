/** @format */

import styled from "styled-components/macro"

import { navHeight } from "components/NavBar/styledComponents"

const SidePanelWrapper = styled.div`
  padding-top: 1.25rem;
  position: absolute;
  width: 14rem;
  right: 0;
  background-color: #111;
  bottom: 0;
  top: ${navHeight};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 1.5s;
`

SidePanelWrapper.displayName = "SidePanelWrapper"

const TotalPings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre;
  span:first-child {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    opacity: 0.8;
    text-transform: capitalcase;
    margin-bottom: 0.375rem;
  }
  span:last-child {
    color: white;
    font-weight: 700;
    font-size: 2rem;
  }
`
TotalPings.displayName = "Total Pings"

//bart:load logged in user's pings
const LoadMyPings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  span:first-child {
    font-weight: 150;
    font-size: 0.75rem;
    line-height: 1rem;
    opacity: 0.8;
    margin-bottom: 0.375rem;
  }
`
LoadMyPings.displayName = "See My Pings"
//end: load logged in user's pings

const PingList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 1rem;
  overflow-y: auto;
  width: 100%;
`

const SidePanelPing = styled.div`
  display: flex;
  padding: 0.5rem 0.75rem;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  img {
    height: 4.5rem;
    width: 4.5rem;
    margin-right: 1rem;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    [data-name="ping-index"] {
      font-size: 0.5625rem;
      font-weight: 400;
      letter-spacing: 1px;
    }
    [data-name="ping-type"] {
      font-size: 1rem;
      font-weight: 600;
    }
    [data-name="ping-location"],
    [data-name="ping-time"] {
      font-size: 0.675rem;
      font-weight: 400;
    }
  }
`

export { PingList, SidePanelPing, SidePanelWrapper, TotalPings, LoadMyPings }
