/** @format */

import React from "react"
import PropTypes from "prop-types"
import { BackgroundOverlay, PingButton, PingWelcomeText, StyledSelector2 } from "./styledComponents"
import i18n from "util/i18n"

const propTypes = {
  activePings: PropTypes.arrayOf(
    PropTypes.shape({
      pingId: PropTypes.string,
      type: PropTypes.string,
      category: PropTypes.string
    })
  ),
  onPingTypeSelect: PropTypes.func.isRequired
}

const defaultProps = {
  activePings: []
}

const PingSelector = React.forwardRef((props, ref) => {
  return (
    <>
      <BackgroundOverlay />
      <StyledSelector2 ref={ref}>
        <PingWelcomeText>{i18n.t("Nav.welcomeMessage")}</PingWelcomeText>
        <div>
          {props.activePings.map(pType => {
            return (
              <PingButton key={pType.id} pingType={pType.type}>
                <button onClick={() => props.onPingTypeSelect(pType.id)}>
                  <img src={pType.svg} alt={pType.type} />
                  {pType.type}
                </button>
              </PingButton>
            )
          })}
        </div>
      </StyledSelector2>
    </>
  )
})

PingSelector.propTypes = propTypes
PingSelector.defaultProps = defaultProps
PingSelector.displayName = "PingSelector"
export default PingSelector
