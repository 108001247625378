/** @format */

import styled from "styled-components"

import { transparentize } from "polished"

import { activePingsList } from "../../App"

const LeafNode = styled.div`
  display: flex;
  color: black;
  padding: 1rem;
  font-family: "Poppins";
  font-size: 1rem;
  flex-direction: column;
  justify-content: center;
  border-radius: 1rem;
  width: 20rem;
  height: 8rem;
  position: relative;
  background: white;

  > span {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    z-index: 2;
  }
`
LeafNode.displayName = "LeafNode"

const PingCount = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  > span:first-child {
    letter-spacing: 0.125rem;
    font-size: 0.625rem;
    padding-right: 0.25rem;
  }
  > span:last-child {
    font-weight: 700;
    font-size: 0.75rem;
  }
`
PingCount.displayName = "PingCount"

const PingDate = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  padding: 0.5rem 1rem;
  > span:first-child {
    font-size: 0.5rem;
    line-height: 0.75rem;
    color: black;
    letter-spacing: 0.125rem;
    font-weight: 400;
  }
  > span:nth-child(2) {
    font-weight: 700;
    color: #232323;
    font-size: 1.3125rem;
  }
  > span:last-child {
    font-weight: 700;
    color: #b0b0b0;
    font-size: 0.5rem;
    line-height: 0.75rem;
    letter-spacing: 0.125em;
  }
`
PingDate.displayName = "PingDate"

const PingTime = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.625rem 1rem;
  color: #232323;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1rem;
`
PingTime.displayName = "PingTime"

const PingLocation = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.625rem 1rem;
  color: #232323;
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1rem;
`
PingLocation.displayName = "PingLocation"

const PingLeafImage = styled.div`
  height: 5rem;
  width: 5rem;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  img {
    height: 3.75rem;
    width: 3.75rem;
  }
  box-shadow: 0 0 0 0.5rem
    ${props =>
      transparentize(0.8, activePingsList.find(p => p.type.toLowerCase() === props.type).color)};
`
PingLeafImage.displayName = "PingLeafImage"

export { LeafNode, PingCount, PingDate, PingTime, PingLocation, PingLeafImage }
