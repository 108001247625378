/** @format */

import React from "react"
import moment from "moment"

import { SidePanelPing } from "./styledComponents"
import { activePingsList } from "../../App"

const SinglePing = ({ ping }) => {
  const { pingts, pingid, pingtypeid, city, state, country } = ping
  const pingtype = activePingsList.find(p => p.id === Number(pingtypeid))
  return (
    <SidePanelPing>
      <img src={pingtype.svg} alt={pingtype.type} />
      <div>
        <span data-name="ping-index">{`#${pingid}`}</span>
        <span data-name="ping-type">{pingtype.type}</span>
        <span data-name="ping-location">{city + ", " + state + ", " + country}</span>
        <span data-name="ping-time">{moment.unix(pingts / 1000).fromNow()}</span>
      </div>
    </SidePanelPing>
  )
}

SinglePing.displayName = "SinglePing"

export default SinglePing
