/** @format */

import styled from "styled-components"

export const AppWrapper = styled.div`
  display: grid;
  grid-template-areas:
    "header"
    "main";
`

export const Main = styled.main`
  background: black;
  bottom: 0;
  grid-area: main;
  left: 0;
  position: absolute;
  right: 0;
  top: 4.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  a,
  a:visited,
  a:hover,
  a:focus {
    color: #89cff0;
  }

  > div {
    width: 100%;
    margin: auto;
    padding: 0 1rem;
  }

  .ping-leaf-popup {
    max-width: 100% !important;
    .mapboxgl-popup-content {
      padding: 0;
      background: transparent;
    }
  }
`

export const Atma = styled.h1`
  margin: 0;
  padding: 2rem 1rem 1rem;
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 700;
  letter-spacing: 2px;
`

export const Hero = styled.div`
  position: relative;
  height: fit-content;
  > div {
    background: radial-gradient(transparent, 80%, black);
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  img {
    max-width: 100%;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  justify-content: space-between;
  @media (max-width: 50rem) {
    flex-wrap: wrap;
  }
`

export const Column = styled.div`
  width: 100%;
  &:last-child {
    align-items: center;
    justify-content: center;
  }
  &#leaf {
    justify-content: flex-start;
  }
  display: flex;
  flex-direction: column;

  ${props => props.center && `align-items: center;`};

  img {
    max-height: 30rem;
    max-width: 100%;
  }
`

export const WelcomeText = styled.div`
  margin-top: -3rem;
  font-size: 1.25rem;
  line-height: 2rem;
  z-index: 3;
`

export const HeaderText = styled.h3`
  font-size: 1.75rem;
  font-weight: 600;
  margin: 1rem 0;
  padding-top: 1rem;

  + p {
    margin: 0;
  }
`

export const SmallHeaderText = styled.h4`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0.75rem 0;
  padding-top: 0.75rem;

  + p {
    margin: 0;
  }
`

export const BodyText = styled.div`
  font-size: 1rem;
  line-height: 1.5;

  li {
    margin-bottom: 0.25rem;
  }
  ul {
    display: inline-block;
    text-align: left;
  }
`
export const BuildText = styled.div`
  font-size: 0.5rem;
  line-height: 1.5;
`
export const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  > div {
    max-width: 72rem;
    margin: auto;
  }

  @media (max-width: 45rem) {
    top: 15rem;
  }

  #faq-section {
    ${Column} {
      ${SmallHeaderText},
      ${BodyText} {
        text-align: center;
        line-height: 2;
      }
      &:last-child {
        margin-bottom: 2rem;
      }
      &:not(:last-child):after {
        content: "~";
        font-size: 2rem;
        padding: 1rem 0 0;
      }
    }
  }
`
