import React from "react"
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // use lifecycle to catch errors in any components below and re-render fallback UI
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Fallback UI
      return (
        <div>
          <h2 style={{color: "white"}}>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap", color: "white" }}>
            {this.state.error && this.state.error.toString()}
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // without error render normally in happy flow 😀😀
    return this.props.children;
  }
}
