/** @format */

const translations = {
  translation: {
    Nav: {
      aboutUs: "Über uns",
      createNewPing: "Erstelle eine neue Ping",
      info: "Die Info",
      login: "Einloggen",
      logout: "Ausloggen",
      map: "Karte",
      persona: "Persona",
      ping: "Ping",
      stats: "Statistiken"
    }
  }
}

export default translations
