/** @format */

import styled from "styled-components"

export const AppWrapper = styled.div`
  display: grid;
  grid-template-areas:
    "header"
    "main";
`

export const RecentPingDisplay = styled.div`
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: grid;
  height: calc(100% - 4.5rem);
  left: 0;
  position: absolute;
  right: 0;
  top: 4.5rem;
  z-index: 2;
  grid-columns: repeat(3, 1fr);
  grid-rows: repeat(3, 1fr);
  grid-template-areas:
    ". . ."
    ". leaf ."
    ". total .";
`

RecentPingDisplay.displayName = "RecentPingDisplay"

export const PingCreatedSection = styled.div`
  width: 16rem;
  height: 8rem;
  background: rgba(0, 0, 0, 0.2);
  border-top-left-radius: 8rem;
  border-top-right-radius: 8rem;
  border: 1px dashed white;
  border-bottom: 0;
  border-color: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  flex-direction: column;
  position: absolute;
  bottom: 100%;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1rem;
  border-color: rgba(255, 255, 255, 0.4);
  > div {
    font-size: 0.5rem;
    padding-top: 0.5rem;
    font-weight: 400;
    padding-bottom: 1.5rem;
  }
`

export const LeafWrapper = styled.div`
  grid-area: leaf;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    position: relative;
  }
`

export const Main = styled.main`
  bottom: 0;
  grid-area: main;
  left: 0;
  position: absolute;
  right: 0;
  top: 4.5rem;

  .ping-leaf-popup {
    max-width: 100% !important;
    .mapboxgl-popup-content {
      padding: 0;
      background: transparent;
    }
  }
`
