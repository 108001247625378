/** @format */

import React from "react"
import PropTypes from "prop-types"
import { startCase } from "lodash"
import moment from "moment"
import { locInfo } from "services/pingService"
import {
  LeafNode,
  PingCount,
  PingDate,
  PingTime,
  PingLocation,
  PingLeafImage
} from "./styledComponents"

import i18n from "util/i18n"

const propTypes = {
  pingInfo: PropTypes.object,
  coords: PropTypes.object
}

const PingLeaf = function(props) {
  let locData = locInfo(props.pingInfo.pingid)
  props.pingInfo["city"] = "NA"
  props.pingInfo["state"] = "NA"
  props.pingInfo["country"] = "NA"

  if (locData) {
    props.pingInfo["city"] = locData.city
    props.pingInfo["state"] = locData.state
    props.pingInfo["country"] = locData.country
  }
  const timeInfo = moment(moment.unix(props.pingInfo.pingts / 1000))
    .local()
    .format("ddd,MMM,Do,hh:mm A")
    .split(",")

  return (
    <React.Fragment>
      <PingLeafImage type={props.pingInfo.iconUrl} position="behind" />
      <LeafNode>
        <PingCount>
          <span>{`${i18n.t("Labels.ping")}#`}</span>
          <span>{props.pingInfo.pingid}</span>
        </PingCount>
        <PingDate>
          <span>{timeInfo[1]}</span>
          <span>{timeInfo[2]}</span>
          <span>{timeInfo[0]}</span>
        </PingDate>
        <PingTime>{timeInfo[3]}</PingTime>
        <PingLocation>
          {props.pingInfo.city + ", " + props.pingInfo.state + ", " + props.pingInfo.country}
        </PingLocation>
        <PingLeafImage type={props.pingInfo.iconUrl}>
          <img
            src={"/static/media/" + props.pingInfo.iconUrl + ".svg"}
            alt={props.pingInfo.title}
          />
        </PingLeafImage>
        <span>{startCase(props.pingInfo.iconUrl)}</span>
      </LeafNode>
    </React.Fragment>
  )
}

PingLeaf.displayName = "PingLeaf"
PingLeaf.propTypes = propTypes
export default PingLeaf
